import { RaidDataDuel, RaidDataRaid, RaidState } from './types';

export const testData: RaidState<RaidDataRaid> = {
	ActiveRaid: {
		Type: 'raid',
		MaxPlayers: 6,
		CountdownSeconds: 20,
		RaidID: 'raid_wi_33yeWXeGw1xY0Xqtk3kyhp',
		Status: 'waiting-for-party',
		Seed: 4095887749659435000,
		Players: [
			{
				UserID: 'twitch:805822595',
				Name: 'Hades32de',
				Level: 9,
				IsPartyHost: true,
				Items: [
					{
						Type: 'title',
						Rarity: 'Rare',
						Title: 'Freischütz',
						Style: 'title',
						Hinge: {
							X: 0,
							Y: 0,
						},
						Size: {
							Width: 0,
							Height: 0,
						},
					},
					{
						Type: 'body_armor',
						Rarity: 'Uncommon',
						SrcURL: 'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2khgt5B6m747WoXSomwwJgP6xW1_af6a4781064f.svg',
						Title: 'a1',
						Style: 'armor-1',
						Hinge: {
							X: -3,
							Y: 0,
						},
						Size: {
							Width: 15,
							Height: 12,
						},
					},
					{
						Type: 'helmet',
						Rarity: 'Rare',
						SrcURL: 'https://assets.tangia.co/dungeon/bespoke-helmet-3.png',
						Title: 'h1',
						Hinge: { X: -5, Y: -7 },
						Size: { Height: 20, Width: 21 },
						Style: 'custom',
					},
					{
						Type: 'offhand',
						Rarity: 'Common',
						SrcURL: 'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2kvc676FmHUH87Cl7jYPPKq01hy_0a57b9dab4e8.svg',
						Title: 'Celadon shield',
						Style: 'shield-1',
						Hinge: {
							X: -2,
							Y: 2,
						},
						Size: {
							Width: 12,
							Height: 14,
						},
					},
					{
						Type: 'glove',
						Rarity: 'Epic',
						SrcURL: 'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2lEofQeaIQi5Y7Uz00LSk6ccFgJ_4b912a3af608.svg',
						Title: 'first gloves',
						Style: 'glove-1',
						Hinge: {
							X: 0,
							Y: 0,
						},
						Size: {
							Width: 7,
							Height: 7,
						},
						SecondaryImage:
							'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2lEofQeaIQi5Y7Uz00LSk6ccFgJ_78e293f55034.svg',
						SecondarySize: {
							Width: 7,
							Height: 7,
						},
						SecondaryHinge: {
							X: 0,
							Y: 0,
						},
					},
					{
						Type: 'weapon',
						Rarity: 'Rare',
						SrcURL: 'https://assets.tangia.co/dungeon/bespoke-weapon-4.png',
						Title: 'Bow of the Musician',
						Style: 'custom',
						Hinge: {
							X: 6,
							Y: -19,
						},
						Size: {
							Width: 6,
							Height: 29,
						},
					},
				],
				HairStyle: '2',
				HairColor: '#8d3525',
				SkinColor: '#68813b',
			},
		],
		Events: [
			'⚔️ hades32de killed an Orc',
			'⚔️ hades32de killed an Orc',
			'🎁 hades32de was rewarded with loot!',
			'💀 hades32de was killed by a Goblin',
			'☠️️ Everyone died! ☠️️️',
			'hades32de gained 10XP',
			'⚔️ hades32de killed an Orc',
			'⚔️ hades32de killed an Orc',
			'🎁 hades32de was rewarded with loot!',
			'💀 hades32de was killed by a Goblin',
			'☠️️ Everyone died! ☠️️️',
			'hades32de gained 10XP',
			'⚔️ hades32de killed an Orc',
			'⚔️ hades32de killed an Orc',
			'🎁 hades32de was rewarded with loot!',
			'💀 hades32de was killed by a Goblin',
			'☠️️ Everyone died! ☠️️️',
			'hades32de gained 10XP',
			'⚔️ hades32de killed an Orc',
			'⚔️ hades32de killed an Orc',
			'🎁 hades32de was rewarded with loot!',
			'💀 hades32de was killed by a Goblin',
			'☠️️ Everyone died! ☠️️️',
			'hades32de gained 10XP',
			'THE END',
		],
		KilledPlayers: ['twitch:805822595'],
		DungeonImageURL: 'https://assets.tangia.co/dungeon/dungeon-1.png',
		DungeonName: 'affe',
		StartedAt: new Date().toISOString(), //'2024-08-05T08:16:56.8706Z',
	},
};

export const testData2: RaidState<RaidDataRaid> = {
	ActiveRaid: {
		Type: 'raid',
		Seed: 3839162363342838300,
		Events: [
			'🗡️ hades32de slayed his own mirror image +100XP!',
			'🎁 hades32de was rewarded with loot!',
			'🗡️ hades32de slayed his own mirror image +100XP!',
			'🗡️ hades32de defeated a Goblin! +100XP',
			'🎁 hades32de was rewarded with loot!',
			'💀 hades32de died of dysentery',
			'☠️️ Everyone died! ☠️️️',
			'hades32de gained 10XP',
		],
		KilledPlayers: ['twitch:805822595'],
		MaxPlayers: 6,
		StartedAt: new Date().toISOString(), //'2024-08-05T17:19:24.542005Z',
		Players: [
			{
				UserID: 'twitch:805822595',
				Name: 'hades32de',
				IsPartyHost: true,
				HairStyle: '1',
				HairColor: '#777777',
				SkinColor: '#FFFFFF',
				Items: [
					{
						Type: 'body_armor',
						SrcURL: 'https://assets.tangia-staging.co/dngn_XcgOlYNGewI2ioRDWY2ci3',
						Title: 'nice shirt',
						Style: 'armor-1',
						Rarity: 'Common',
						Hinge: {
							Y: 1,
							X: -3,
						},
						Size: {
							Height: 12,
							Width: 15,
						},
					},
					{
						Hinge: {
							X: 5,
							Y: 25,
						},
						Size: {
							Width: 10,
							Height: 14,
						},
						Type: 'weapon',
						Rarity: 'Uncommon',
						SrcURL: 'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2kEmwlIUcFg3rGL9z51icYPCNBE_925e4c9b5c62.svg',
						Title: 'some sword',
						Style: 'sword-2',
					},
					{
						Size: {
							Height: 0,
							Width: 0,
						},
						Type: 'title',
						Rarity: 'Legendary',
						Title: 'Freischütz',
						Style: 'title',
						Hinge: {
							X: 0,
							Y: 0,
						},
					},
					{
						Size: {
							Width: 13,
							Height: 14,
						},
						Type: 'helmet',
						Rarity: 'Epic',
						SrcURL: 'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2kEnIDqt47s1GhU24TWMVO91VJ4_88b9a0163244.svg',
						Title: 'protection from above',
						Style: 'helmet-1',
						Hinge: {
							X: 11,
							Y: 10,
						},
					},
					{
						Style: 'shield-2',
						Hinge: {
							X: 5,
							Y: 5,
						},
						Size: {
							Width: 10,
							Height: 14,
						},
						Type: 'offhand',
						Rarity: 'Rare',
						SrcURL: 'https://assets.tangia-staging.co/creator_content/user_2EnGsVuHB3rBYLT2nupRTHRVXPJ/dngn_2kExEtKPtY0sEu5DASHdDwuYLfK_80f090f55048.svg',
						Title: 'moshishield',
					},
				],
			},
		],
		CountdownSeconds: 20,
		DungeonImageURL: 'https://assets.tangia.co/actions/Screenshot%202023-10-02%20at%2011.32.58%E2%80%AFPM_b1cb969b4988.png',
		RaidID: 'raid_wi_Vt0Z7blNOcku1O4fq8oPPT',
		DungeonName: 'The first Tangia Dungeon',
		Status: 'completed',
	},
};

for (let i = 1; i <= 3; i++) {
	testData.ActiveRaid!.Players!.push({
		...testData.ActiveRaid!.Players![0],
		UserID: 'fake' + i,
		Name: 'Faker Nr.' + i,
		IsPartyHost: false,
	});
}
// a few dead
testData.ActiveRaid!.KilledPlayers = testData.ActiveRaid!.Players!.map((player) => player.UserID).filter((_, i) => i > 1);

for (let i = 1; i <= 4; i++) {
	testData2.ActiveRaid!.Players!.push({
		...testData2.ActiveRaid!.Players![0],
		UserID: 'fake' + i,
		Name: 'Faker Nr.' + i,
		IsPartyHost: false,
	});
}
// all dead
testData2.ActiveRaid!.KilledPlayers = testData2.ActiveRaid!.Players!.map((player) => player.UserID);

export const duelTestData: RaidState<RaidDataDuel> = {
	ActiveRaid: {
		MaxPlayers: 2,
		CountdownSeconds: 16,
		ChallengeSeconds: 10,
		ChallengedUserName: 'theRealMrBeast12',
		DungeonImageURL: 'https://assets.tangia.co/dungeon/dungeon-1.png',
		Status: 'waiting-for-party', // data is already for completed, we wait for manual click for debugging
		Players: [
			{
				Level: 6,
				IsPartyHost: true,
				HairStyle: '1',
				HairColor: '#777777',
				Infamy: 461,
				UserID: 'user_2gS9iuHNIvPPcnEN0Hvsz1qxT6V',
				Name: 'MR Tangia',
				Items: [],
				SkinColor: '#FFFFFF',
			},
			//testData.ActiveRaid!.Players![1],
		],
		DuelEvents: [
			{
				Health: [100, 85],
				UserID: 'user_2gS9iuHNIvPPcnEN0Hvsz1qxT6V',
				Type: 'attack',
				Message: '15',
			},
			{
				Type: 'attack',
				Message: '10',
				Health: [90, 85],
				UserID: testData.ActiveRaid!.Players![1].UserID,
			},
			{
				UserID: 'user_2gS9iuHNIvPPcnEN0Hvsz1qxT6V',
				Type: 'attack',
				Message: '15',
				Health: [90, 45],
			},
			{
				UserID: testData.ActiveRaid!.Players![1].UserID,
				Type: 'attack',
				Message: '0',
				Health: [90, 45],
			},
			{
				Health: [90, 45],
				UserID: 'user_2gS9iuHNIvPPcnEN0Hvsz1qxT6V',
				Type: 'attack',
				Message: '0',
			},
			{
				UserID: testData.ActiveRaid!.Players![1].UserID,
				Type: 'attack',
				Message: '10',
				Health: [45, 45],
			},
			{
				UserID: 'user_2gS9iuHNIvPPcnEN0Hvsz1qxT6V',
				Type: 'attack',
				Message: '25',
				Health: [45, 20],
			},
			{
				Type: 'attack',
				Message: '45',
				Health: [0, 20],
				UserID: testData.ActiveRaid!.Players![1].UserID,
			},
			{
				UserID: testData.ActiveRaid!.Players![1].UserID,
				Type: 'victory',
				Message: 'MR Tangia is victorious!',
				Health: [0, 20],
			},
			{
				UserID: 'user_2gS9iuHNIvPPcnEN0Hvsz1qxT6V',
				Type: 'infamy',
				Message: '-23',
				Health: null,
			},
			{
				UserID: testData.ActiveRaid!.Players![1].UserID,
				Type: 'infamy',
				Message: '23',
				Health: null,
			},
		],
		Type: 'duel',
		DungeonName: 'kill it with fire',
		StartedAt: new Date().toISOString(),
		Seed: 14279231090395425000,
		RaidID: 'raid_wi_EGM1RPMZ6kdT2TOm1FGoyi',
	},
	ServerTime: new Date().toISOString(),
	Settings: {
		VoucherDropRate: 0,
		JoinTimer: 16,
		Position: {
			X: 14,
			Y: 515,
			Height: 548,
			Width: 470,
		},
		CustomDeathMessages: ['cut themselves on paper'],
		CustomKillMessages: ['killed a slug', 'slipped on slug slime'],
		// EnableNotificationSound: true,
		NotificationSoundVolume: 0.2,
		Enabled: true,
		CustomEventMessages: ['takes their asthma spray'],
	},
};
