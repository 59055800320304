import { Character } from './Character';
import { testData } from './debug';

export function AvatarTestPage() {
	return (
		<div className="grid grid-cols-2 max-w-[800px] mx-auto mt-20">
			<Character player={testData.ActiveRaid!.Players![0]} state="standing" flip={false} />
			<Character player={testData.ActiveRaid!.Players![0]} state="standing" flip={true} />
			<Character player={testData.ActiveRaid!.Players![0]} state="running" flip={false} />
			<Character player={testData.ActiveRaid!.Players![0]} state="running" flip={true} />
		</div>
	)
}