import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

import './index.css';
import { FrontlinkProvider } from 'frontlink';
import { avatarTestPage, backendAPI, streamKey } from './global_config';
import { AvatarTestPage } from './TestPage';

const animationStarts: Record<string, CSSNumberish | null> = {};

function synchronizeAnimations() {
	// inspired by https://stackoverflow.com/questions/4838972/how-to-sync-css-animations-across-multiple-elements
	window.addEventListener('animationstart', function (event) {
		const animation = (event.target as HTMLElement).getAnimations().find((animation) => animation.id === event.animationName);
		if (!animation) return;
		if (animationStarts[animation.id] === undefined) {
			animationStarts[animation.id] = animation.startTime;
		} else {
			animation.startTime = animationStarts[animation.id];
		}
	});
}

synchronizeAnimations();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<FrontlinkProvider
			api={`${backendAPI.replace('http', 'ws')}/frontlink_stream?key=${streamKey}&src=dungeon`}
			debugLog={/localhost|127.0.0.1/.test(window.location.hostname)}
		>
			{avatarTestPage ? <AvatarTestPage /> : <App />}
		</FrontlinkProvider>
	</React.StrictMode>,
);
